import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SideBarView from "../components/SideBarView"
import dateFormat from "dateformat"
import { CodeBlockView } from "../components/HelperComponents"
import { Typography, Grid } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})


// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function(dowOffset) {
  dowOffset = typeof (dowOffset) == "number" ? dowOffset : 0 //default dowOffset to zero
  const newYear = new Date(this.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = (day >= 0 ? day : day + 7)
  const daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
  let weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      const nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

const programmingRoutines = [
  {
    title: "Microsoft Excel / LibreOffice Calc",
    code: `=ISOWEEKNUM(TODAY())
// or (in older versions):
=WEEKNUM(TODAY(),21)`,
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Where the return type '21' is ISO-8601 (week
      starting on Monday). <br/><br/>In Excel 2007 your best choice is WEEKNUM(TODAY(),2) (2=week starting
      Monday). <br/>WEEKNUM(TODAY()) will show the week number with weeks starting on Sunday (return type = 1).</p>
  },
  {
    title: "Google Docs Spreadsheet",
    code: "=WEEKNUM(TODAY();21)",
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Type (here '21') is compatible with
      Excel/LibreOffice, 21 is ISO-8601</p>
  },
  {
    title: "PHP",
    code: "$weekNumber = date(\"W\"); ",
    language: "php",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>or date("W", epoch) for other week numbers. Remember
      to use capital 'W' not 'w'.</p>
  },
  {
    title: "Python",
    code: "datetime.date.today().isocalendar()[1]",
    language: "python",
    description: null
  },
  {
    title: "PERL",
    code: "my $weekNumber = POSIX::strftime(\"%V\", gmtime time);",
    language: "perl",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Replace time with other epoch/UNIX timestamps for
      other week numbers.</p>
  },
  {
    title: "Java",
    code: "Calendar now = Calendar.getInstance();\n" +
      "now.get(Calendar.WEEK_OF_YEAR);",
    language: "java",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Use WEEK_OF_YEAR in the Calendar class.</p>
  },
  {
    title: "JavaScript",
    code: "Date.prototype.getWeek = function () {\n" +
      "    var target  = new Date(this.valueOf());\n" +
      "    var dayNr   = (this.getDay() + 6) % 7;\n" +
      "    target.setDate(target.getDate() - dayNr + 3);\n" +
      "    var firstThursday = target.valueOf();\n" +
      "    target.setMonth(0, 1);\n" +
      "    if (target.getDay() != 4) {\n" +
      "        target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);\n" +
      "    }\n" +
      "    return 1 + Math.ceil((firstThursday - target) / 604800000);\n" +
      "}\n" +
      "\n" +
      "var d= new Date();\n" +
      "alert(d.getWeek());",
    language: "javascript",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Extend the Date class using the above code.</p>
  },
  {
    title: "MySQL",
    code: "SELECT WEEKOFYEAR(NOW())",
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Replace now() with other dates eg. SELECT
      WEEKOFYEAR('2021-02-20');<br/>
      (You can also use the WEEK function with mode=3 select week(now(),3))
    </p>
  },
  {
    title: "PostgreSQL",
    code: "SELECT * FROM EXTRACT(WEEK from current_date())",
    language: "sql",
    description: null
  },
  {
    title: "MS SQL",
    code: "SELECT DATEPART( wk, GETDATE() )",
    language: "sql",
    description: null
  },
  {
    title: "Oracle",
    code: "SELECT to_char(sysdate, 'IW') FROM DUAL ",
    language: "sql",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>IW: Week of year (1-52 or 1-53) based on the
      ISO-8601 standard.<br/>
      WW: Week of year (1-53) where week 1 starts on the first day of the year and continues to the seventh day of the
      year (Mostly NOT used)</p>
  },
  {
    title: "iSeries SQL",
    code: "SELECT WEEK(NOW())  from sysibm.sysdummy1",
    language: "sql",
    description: null
  },
  {
    title: "iPhone/Mac",
    code: "[NSString stringWithFormat:@\"Week %d\",\n" +
      "  [calendar ordinalityOfUnit:NSWeekCalendarUnit inUnit:NSYearCalendarUnit forDate:date]];",
    language: "swift",
    description: null
  },
  {
    title: "iPhone/iOS/Swift",
    code: "let gregorian = NSCalendar(calendarIdentifier: NSCalendarIdentifierGregorian)!\n" +
      "gregorian.firstWeekday = 2 // Monday\n" +
      "gregorian.minimumDaysInFirstWeek = 4\n" +
      "let components =\n" +
      "    gregorian.components(.WeekOfYearCalendarUnit | .YearForWeekOfYearCalendarUnit, fromDate: date)\n" +
      "let week = components.weekOfYear\n" +
      "let year = components.yearForWeekOfYear",
    language: "swift",
    description: null
  },
  {
    title: "R",
    code: "lubridate::week()",
    language: "R",
    description: null
  },
  {
    title: "Ruby",
    code: "week_number = Time.now.strftime(\"%U\")\n",
    language: "",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Replace Time.now with Time.local(year,month,day) for
      other dates.<br/>
      Formats:<br/>
      %U - Week number of the year, starting with the first Sunday as the first day of the first week (00..53)<br/>
      %V - Week number of year according to ISO-8601 (01..53)<br/>
      %W - Week number of the year, starting with the first Monday as the first day of the first week (00..53)</p>
  },
  {
    title: "Go",
    code: "year, week := time.Now().ISOWeek()",
    language: "go",
    description: null
  },
  {
    title: "Linux/Unix shell (bash)",
    code: "date +%V",
    language: "bash",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Returns the ISO-8601 week number.<br/>
      Other formats under 'Ruby'. More details in the Linux Programmer's Manual</p>
  },
  {
    title: "Lua",
    code: "Current_week = os.date(\"%V\")",
    language: "lua",
    description: <p style={{ marginTop: "10px", fontSize: "16px" }}>Formats: see formats under 'Ruby'.</p>
  },
  {
    title: "Windows PowerShell",
    code: "Get-Date -UFormat %V\n" +
      "# or\n" +
      "\"{0:d2}\" -f ($(Get-Culture).Calendar.GetWeekOfYear($(Get-Date),\n" +
      "  [System.Globalization.CalendarWeekRule]::FirstFourDayWeek, [DayOfWeek]::Monday))",
    language: "bash",
    description: null
  },
  {
    title: "X++ (Microsoft Dynamics AX)",
    code: "int weeknum;\n" +
      "weeknum = weekOfYear(today());",
    language: "c",
    description: null
  },
  {
    title: "C/AL (Microsoft Dynamics NAV)",
    code: "MESSAGE(FORMAT(CALCDATE('CW', TODAY), 0, '<week>'));",
    language: "sql",
    description: null
  }
]

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const WeekNumbers = () => {
  const currentDate = new Date()
  const weekDayOffset = 1
  const year = currentDate.getFullYear()
  const weekOffset = (currentDate.getDay() + (7 - weekDayOffset)) % 7
  const weekNumber = (new Date()).getWeek(weekDayOffset)
  const weekStartDate = dateFormat((new Date((new Date()).getTime() - weekOffset * 86400000)), "dddd, mmmm dS, yyyy")
  return (
    <Container>
      <CustomHeader title={`What's the Current Week Number?`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Current week is ...
          </Typography>
          <div style={{
            width: "100%",
            height: "100px",
            border: "1px solid",
            borderRadius: "5px",
            textAlign: "center",
            margin: "auto",
            padding: "25px 0 25px 0"
          }}>
            <Typography variant={`h5`}><b>Week {String(weekNumber).padStart(2, "0")}</b></Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Week {String(weekNumber).padStart(2, "0")} is from {weekStartDate} until (and including) Sunday, December
              26, 2021.</p>

            <p>Week number according to the ISO-8601 standard, weeks starting on Monday. The first week of the year is
              the week that contains that year's first Thursday (='First 4-day week'). ISO representation: 2021-W51</p>

            <p>The highest week number in a year is either 52 or 53.<br/>
              2021 has 52 weeks.</p>

            <p>ISO 8601 is not the only week numbering system in the world, other systems use weeks starting on Sunday
              (US) or Saturday (Islamic).</p>
            <p>Lists of week numbers by year :&nbsp;
              <b>
                <Link to={`/weeks/${year - 1}`}>{year - 1}</Link> -&nbsp;
                <Link sx={{ fontSize: "21px" }} to={`/weeks/${year}`}>{year}</Link> -&nbsp;
                <Link to={`/weeks/${year + 1}`}>{year + 1}</Link>
              </b>
            </p>
          </div>
          <Typography
            variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Programming routines
          </Typography>
          <div>
            {programmingRoutines.map(programmingRoutineView)}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default WeekNumbers

export const Head = () => {
  const siteMetadata = {
    description: "week number by date in a year will be shown with programming explanation in multiple languages",
    description2: "",
    keywordsContent: "week, number, programming, code snippets, c, php, java, javascript"
  }
  return (
    <Seo
      title="Unix / Epoch - Display week number in year and code snippets for conversion"
      canonicalUrl={"https://www.epochconverter.io/week-numbers/"}
      siteMetadata={siteMetadata}
    />
  )
}
